








import { defineComponent, computed, PropType } from '@nuxtjs/composition-api'
import Breadcrumbs from '~/components/global/navigation/Breadcrumbs.vue'
import PageTitle from '~/components/feature/static-content/PageTitle.vue'
import TemplateColumnLayout from '~/components/feature/static-content/TemplateColumnLayout.vue'

import { BreadcrumbsData } from '~/types/breadcrumbs'
import type { ContentPage as ContentPageT } from '~/modules/contentstack/types'

type ContentPageData = {
  pageData: ContentPageT
  breadcrumbs: BreadcrumbsData
}

export default defineComponent({
  components: {
    Breadcrumbs,
    PageTitle,
    TemplateColumnLayout,
  },
  props: {
    data: {
      type: Object as PropType<ContentPageData>,
      required: true,
    },
  },
  setup(props) {
    // Content page title
    const contentPageTitle = computed(() => props?.data?.pageData?.page?.title ?? '')

    return {
      contentPageTitle,
    }
  },
})
