







import consola from 'consola'
import isEmpty from 'lodash/fp/isEmpty'
import { decode } from 'html-entities'
import { computed, defineComponent, useAsync, useContext, useRoute } from '@nuxtjs/composition-api'

import ContentPage from '~/components/feature/static-content/page/ContentPage.vue'
import NewsPage from '~/components/feature/static-content/page/NewsPage.vue'
import PathwayDiagramPage from '~/components/feature/static-content/page/PathwayDiagramPage.vue'
import ProteinDomainPage from '~/components/feature/static-content/page/ProteinDomainPage.vue'
import ProtocolPage from '~/components/feature/static-content/page/ProtocolPage.vue'
import VideoPage from '~/components/feature/static-content/page/VideoPage.vue'

// Composables
import { useCMSMeta } from '~/composables/cms/useCMSMeta'
import { useCMSPage } from '~/composables/cms/useCMSPage'

// Types
import { Metadata } from '~/types/cms/metadata'

export default defineComponent({
  components: {
    ContentPage,
    NewsPage,
    PathwayDiagramPage,
    ProteinDomainPage,
    ProtocolPage,
    VideoPage,
  },
  setup() {
    const ctx = useContext()
    const route = useRoute()

    const data = useAsync<any>(() => ctx.payload)

    consola.info('Rendering Route: ', route.value.path)

    // Content page title
    const pageTitle = computed(() => data.value?.pageData?.page?.title || '')

    // Support SEO meta tags
    const pageMeta = computed(() => {
      if (data?.value?.pageData?.seo_metadata && data?.value?.pageData.seo_metadata.length > 0) {
        const metadata = data?.value?.pageData.seo_metadata as Metadata[]
        return metadata.map(
          (data) =>
            ({
              type: data.type,
              entity: data.type,
              value: data.type === 'title' ? decode(data.value) : data.value,
            }) as Metadata
        )
      }
    })

    // SEO metadata
    useCMSMeta(
      () => pageMeta.value as Metadata[],
      () => ({
        title: pageTitle.value,
        meta: pageMeta.value,
        link: [
          {
            rel: 'canonical',
            href: ctx.$config.cst.domainDefault + route.value.path,
          },
        ],
      })
    )

    return { data }
  },
  async asyncData(ctx) {
    const { page } = useCMSPage(ctx)

    try {
      await page.fetch()

      if (isEmpty(page.pageData)) {
        return ctx.error({ statusCode: 404 })
      }

      ctx.payload = { pageData: page.pageData, breadcrumbs: page.breadcrumbs, componentName: page.componentName }
    } catch (e: any) {
      consola.error(e)
      return ctx.error({ message: e.message, statusCode: 500 })
    }
  },
  head: {},
})
