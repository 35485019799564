import { render, staticRenderFns } from "./NewsPage.vue?vue&type=template&id=79c59aa2&scoped=true&"
import script from "./NewsPage.vue?vue&type=script&lang=ts&"
export * from "./NewsPage.vue?vue&type=script&lang=ts&"
import style0 from "./NewsPage.vue?vue&type=style&index=0&id=79c59aa2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79c59aa2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CstButton: require('/cstweb-frontend/libs/storefront-vue/src/components/atoms/Button.vue').default})
