
















import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import RichText from '@/components/feature/static-content/RichText.vue'

// Components
import Breadcrumbs from '~/components/global/navigation/Breadcrumbs.vue'
import PageTitle from '~/components/feature/static-content/PageTitle.vue'

// Types
import { BreadcrumbsData } from '~/types/breadcrumbs'
import { News, GfContentSlotComponentsComponentsRichtext } from '~/modules/contentstack/types'

type NewsPageData = {
  pageData: News
  breadcrumbs: BreadcrumbsData
}

export default defineComponent({
  components: {
    Breadcrumbs,
    PageTitle,
    RichText,
  },
  props: {
    data: {
      type: Object as PropType<NewsPageData>,
      required: true,
    },
  },
  setup(props) {
    const breadcrumbRoutes = computed<BreadcrumbsData>(() => [
      {
        label: 'About Us',
        url: '/about-us',
      },
      {
        label: 'CST Media Room',
        url: '/about-us/our-company/media-room',
      },
    ])

    const pageTitle = computed(() => {
      return props.data.pageData.page?.title ?? ''
    })

    const newsRTEdata = computed<GfContentSlotComponentsComponentsRichtext>(() => {
      return {
        __typename: 'GfContentSlotComponentsComponentsRichtext' as const,
        richtext: {
          json_rich_text: {
            __typename: 'GfContentSlotComponentsComponentsRichtextBlockJsonRichText' as const,
            embedded_itemsConnection: props.data.pageData.json_rich_text?.embedded_itemsConnection,
            json: props.data.pageData.json_rich_text?.json?.at(0),
          },

          __typename: 'GfContentSlotComponentsComponentsRichtextBlock' as const,
        },
      } as GfContentSlotComponentsComponentsRichtext
    })

    return {
      breadcrumbRoutes,
      pageTitle,
      newsRTEdata,
    }
  },
  head: {},
})
