






















import { defineComponent, computed } from '@nuxtjs/composition-api'
import Breadcrumbs from '~/components/global/navigation/Breadcrumbs.vue'
import PageTitle from '~/components/feature/static-content/PageTitle.vue'
import ContentVideoComponent from '~/components/feature/static-content/components/ContentVideo.vue'
import JsonRteField from '~/components/global/cms/JsonRteField.vue'

import { BreadcrumbsData } from '~/types/breadcrumbs'
import type { ContentVideo } from '~/modules/contentstack/types'

type VideoPageData = {
  pageData: ContentVideo
  breadcrumbs: BreadcrumbsData
}

export default defineComponent({
  components: {
    Breadcrumbs,
    PageTitle,
    ContentVideoComponent,
    JsonRteField,
  },
  props: {
    data: {
      type: Object as () => VideoPageData,
      required: true,
    },
  },
  setup(props) {
    // Content page title
    const pageTitle = computed(() => props?.data?.pageData?.page?.title ?? '')

    return {
      pageTitle,
    }
  },
})
