













































































import { defineComponent, computed } from '@nuxtjs/composition-api'
import isEmpty from 'lodash/fp/isEmpty'

// Components
import JsonRteField from '~/components/global/cms/JsonRteField.vue'

// Common
import { hasDocEmptyTexts } from '~/common/utils/static-content/cms'

export default defineComponent({
  components: {
    JsonRteField,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const enrichedPdiData = computed(() => {
      const dataValue = props.data
      const imageConnection = dataValue?.domain_imageConnection?.edges?.[0]?.node
      const examplesConnection = dataValue?.domain_proteins_examplesConnection?.edges?.[0]?.node

      const connectionDomainImageCaption = imageConnection?.caption?.json
      const domainBinding = dataValue?.domain_binding?.json
      const structure = dataValue?.structure?.json
      const structureReferenceList = dataValue?.structure_reference?.json
      const bindingExamples = dataValue?.binding_examples?.json
      const additionalContent = dataValue?.additional_content?.json
      const consensusBindingSites = dataValue?.consensus_binding_sites?.json

      return {
        isConnectionDomainImage: !isEmpty(imageConnection?.media_server_id),
        connectionDomainImageUrl: imageConnection?.media_server_id,
        connectionDomainImageAltText: imageConnection?.alt_text,

        isConnectionDomainImageCaption: !hasDocEmptyTexts(connectionDomainImageCaption),
        connectionDomainImageCaption,

        isDomainBinding: !hasDocEmptyTexts(domainBinding),
        domainBinding,

        isStructure: !hasDocEmptyTexts(structure),
        structure,

        isStructureReferenceList: !hasDocEmptyTexts(structureReferenceList),
        structureReferenceList,

        isProteinsExampleDomainImage: !!examplesConnection?.media_server_id,
        proteinsExampleDomainImageUrl: examplesConnection?.media_server_id,
        proteinsExampleDomainImageAltText: examplesConnection?.alt_text,

        isAdditionalContent: !hasDocEmptyTexts(additionalContent),
        additionalContent,

        isBindingExamples: !hasDocEmptyTexts(bindingExamples),
        bindingExamples,

        isConsensusBindingSites: !hasDocEmptyTexts(consensusBindingSites),
        consensusBindingSites,
      }
    })

    return { enrichedPdiData }
  },
})
