








import { defineComponent, computed } from '@nuxtjs/composition-api'
import Breadcrumbs from '~/components/global/navigation/Breadcrumbs.vue'
import PageTitle from '~/components/feature/static-content/PageTitle.vue'
import ProteinDomainComponent from '~/components/feature/static-content/ProteinDomain.vue'

import { BreadcrumbsData } from '~/types/breadcrumbs'
import type { ProteinDomain } from '~/modules/contentstack/types'

type ProteinDomainPageData = {
  pageData: ProteinDomain
  breadcrumbs: BreadcrumbsData
}

export default defineComponent({
  components: {
    Breadcrumbs,
    PageTitle,
    ProteinDomainComponent,
  },
  props: {
    data: {
      type: Object as () => ProteinDomainPageData,
      required: true,
    },
  },
  setup(props) {
    // Content page title
    const pageTitle = computed(() => props?.data?.pageData?.page?.title ?? '')

    return {
      pageTitle,
    }
  },
})
