import { render, staticRenderFns } from "./VideoPage.vue?vue&type=template&id=49617b89&scoped=true&"
import script from "./VideoPage.vue?vue&type=script&lang=ts&"
export * from "./VideoPage.vue?vue&type=script&lang=ts&"
import style0 from "./VideoPage.vue?vue&type=style&index=0&id=49617b89&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49617b89",
  null
  
)

export default component.exports